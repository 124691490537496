// Size
$tiny: 7px;
$small: 8px;
$medium: 10px;
$large: 11px;
$xlarge: 13px;
$xxlarge: 15px;
p,
li,
a,
pre {
  font-family: $txtFamily;
  letter-spacing: 0.2px;
  color: black;
  font-weight: 500;
}
