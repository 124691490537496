$logoFamily: "Inter";
$titleFmaily: "Inter";
$subTitleFmaily: "Inter";
$txtFamily: "Inter";

// Size
$tiny: 10px;
$small: 11px;
$medium: 13px;
$large: 14px;
$xlarge: 16px;
$xxlarge: 18px;
$xxxlarge: 60px;



h1,
h2,
h3,
h4,
h5 {
  color: $txt;
  font-weight: 500;
}

#inner-container {
  h1 {
    color: white;
    font-size: $xxlarge;
  }
}

#home-container {
  h1 {
    color: white;
    font-size: $xxlarge;
    letter-spacing: 10px;
  }

  h2 {
    color: white;
    font-size: $xlarge;
    letter-spacing: 10px;
    text-transform: uppercase;
  }

  p {
    color: $altTxt;
    &.subtitle {
      color: $primary;
    }
  }
}

.logo-font {
  font-family: $logoFamily, cursive;
  letter-spacing: 10px;
  font-size: 24px;
}

h1 {
  font-family: $titleFmaily;
}

h2 {
  font-family: $subTitleFmaily;
}

h4 {
  font-family: $subTitleFmaily;
  letter-spacing: 3px;
}

p,
li,
a,
pre {
  font-family: $txtFamily;
  letter-spacing: 0.5px;
  color: black;
  font-weight: 900;
}

.form-text {
  color: grey;
}

@media only screen and (max-width: 640px) {
  #home-container {
    h1 {
      font-size: $xxlarge;
      letter-spacing: 3px;
    }
  }
}
