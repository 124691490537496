

.menu {
  background-color: $white;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  z-index: 40;
  width: 100%;
  align-items: center;
}

.logo {
  p {
    cursor: pointer;
    font-size: 40px;
    font-weight: 900;
  }
}

.ant-modal {
  padding-bottom: 0;
  }
  
  .ant-modal-body {
    min-height: calc(100vh - 50px);
  }
  
  .ant-modal-close {
    transform: translateY(15px) translateX(-10px);
    padding: 0;
  }
  .ant-modal-close:hover {
    box-shadow: none;
    border-radius: 30px;
  }

  .ant-modal-close-x {
    width: 40px;
    height: 40px;
    transform: translateY(15px)translateX(-10px);
  }


  .ant-modal-content {
    background-color: rgba(255, 255, 255, 0.8);
  }

.ant-modal-close-x:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 30px;
}

